import { environment as env, environment } from '@environments/environment';

const CLIENT = `${env.apiUrl}/client`;
const PUBLIC = `${env.apiUrl}/public`;

export const API = {
	config: {
		translations: `${PUBLIC}/translations`,
		startupInfo: `${PUBLIC}/startup/info`,
	},
	ads: {
		adList: `${CLIENT}/ads`,
		latestAdList: `${CLIENT}/ads/aisles`,
		adDetail: (id: string) => `${CLIENT}/ads/${id}`,
		completeDraft: (id: string) => `${CLIENT}/ads/${id}/draft/complete`,
		deleteAd: (adId: string) => `${CLIENT}/ads/${adId}`,
		soldAd: (adId: string) => `${CLIENT}/ads/${adId}/sold`,
		adStatusList: `${CLIENT}/ads/status-list`,
		getEmptyViewContent: (countyId) => `${PUBLIC}/filters/county-content/${countyId}`,
		agentAdList: (id: string) => `${CLIENT}/ads/agent/${id}`,
	},
	myAds: {
		myAdList: `${CLIENT}/my/ads`,
	},
	filters: {
		filterList: `${PUBLIC}/filters`,
		filterValues: (filterName: string) => `${PUBLIC}/filters/${filterName}`,
		sectionList: `${PUBLIC}/filters/sections`,
		shelveList: `${PUBLIC}/filters/shelves`,
		aisleList: `${PUBLIC}/filters/aisles`,
		standlist: `${PUBLIC}/filters/stands`,
		availibilitySlots: `${PUBLIC}/filters/availability-slots`,
		getCountyList: `${PUBLIC}/filters/county`,
		getRecentSearch: `${CLIENT}/search-history`,
	},
	auth: {
		phone: `${PUBLIC}/auth/phone`,
		twilioSendOtp: `${PUBLIC}/auth/phone/otp`,
		twilioVerifyPhone: `${PUBLIC}/auth/phone/otp/verification`,
		requestEmailOtp: `${PUBLIC}/auth/email/request-otp`,
		verifyEmailOtp: `${PUBLIC}/auth/email`,
		verifyAgentInvitation: `${PUBLIC}/auth/email/invitation`,
		acceptAgentInvitation: `${PUBLIC}/auth/accept/invitation`,
		rejectAgentInvitation: `${PUBLIC}/auth/agent/invitation/reject`,
		sendTenantOtp: `${CLIENT}/auth/tenant-request-otp`,
		verifyTenantOtp: `${CLIENT}/auth/tenant-verify-otp`,
		createTenantProfile: `${CLIENT}/auth/create/tenant`,
	},
	profile: {
		base: `${CLIENT}`,
		phone: `${CLIENT}/phone/complete`,
		email: `${CLIENT}/profile/email`,
		confirmEmail: `${CLIENT}/profile/email/confirm`,
		businessProfile: `${CLIENT}/business`,
		businessAwsUrl: `${CLIENT}/business-logo/url`,
		checkMobileNoExist: `${CLIENT}/phone/available`,
		preference: `${CLIENT}/preferences`,
		updateProfilePhone: `${CLIENT}/phone/complete`,
		sendOtp: `${CLIENT}/email`,
		verfiyOtp: `${CLIENT}/email/complete`,
		getProfilePicture: `${CLIENT}/profile-picture/url`,
		deleteProfilePicture: `${CLIENT}/profile-picture/url`,
		getAvatar: `${CLIENT}/profile-picture/avatar`,
		updateAvatar: `${CLIENT}/profile-picture/avatar`,
		getRequestedOtp: `${CLIENT}/email/delete/request-otp`,
		changeEmailRequestOtp: `${CLIENT}/email/change/request-otp`,
		changeEmailVerifyOtp: `${CLIENT}/email/verify-otp`,
		updateEmailOtp: `${CLIENT}/email/update/request-otp`,
		unSubscribeEmail: `${PUBLIC}/email/alert/unsubscription`,
		submitSurvey: `${CLIENT}/survey`,
		generatePaymentLink: `${CLIENT}/business/subscription-payment/initialization`,
		verifyPayment: `${CLIENT}/business/subscription-payment/complete`,
		subscription: `${CLIENT}/business-subscription`,
		cancelSubscription: `${CLIENT}/business/subscription-payment/cancel`,
	},
	placeAd: {
		base: `${CLIENT}/ads`,
		imageUpload: (id: string) => `${CLIENT}/ads/${id}/image`,
		deleteImage: (id: string, imageId: string) => `${CLIENT}/ads/${id}/image/${imageId}`,
		reOrderImage: (id: string) => `${CLIENT}/ads/${id}/image-order`,
		updateAd: (id: string) => `${CLIENT}/ads/${id}`,
		editAd: (id: string) => `${CLIENT}/ads/${id}/edit`,
		getAd: (id: string) => `${CLIENT}/ads/${id}`,
		completeAd: (id: string) => `${CLIENT}/ads/${id}/draft/complete`,
		placeAdPaymentInitialization: `${CLIENT}/ads/payment/initialization`,
		planTiers: `${CLIENT}/ads/plan-tiers`,
		paymentComplete: `${CLIENT}/ads/payment/complete`,
		couponPayment: `${CLIENT}/ads/payment/coupon`,
		verifyCoupon: `${CLIENT}/ads/payment/coupon-verify`,
		updateBrochure: (id: string) => `${CLIENT}/ads/${id}/brochure`,
		rotatedImage: (adId: string, imageId: string) => `${CLIENT}/ads/${adId}/image/${imageId}`,
		getEricode: (search: string) => `${CLIENT}/address/eircode/${search}`,
		lastAdDetails: `${CLIENT}/ads/last`,
		mobileAppRedirect: `${environment.mobileAppRedirectAPI}`,
	},
	favorites: {
		favoritesList: `${CLIENT}/favorite/ads`,
		deleteFavoriteAd: (adId: string) => `${CLIENT}/ads/${adId}/favorite`,
		saveFavoriteAd: (adId: string) => `${CLIENT}/ads/${adId}/favorite`,
	},
	inquiry: {
		sendInquiry: (adId: string) => `${CLIENT}/ads/${adId}/enquiry`,
		getInquiry: `${CLIENT}/ads/enquiry`,
		reSubmitInquiry: (adId: string, enquiryId: string) => `${CLIENT}/ads/${adId}/enquiry/resubmit/${enquiryId}`,
		enquiryById: (id: string) => `${CLIENT}/agent-hub/enquiries/${id}`,
		changeEnquiryStatus: (enquiryId: string) => `${CLIENT}/agent-hub/enquiries/${enquiryId}`,
	},
	reportAds: {
		getReportReasons: `${CLIENT}/ads/report-reasons`,
		reportAd: (adId: string) => `${CLIENT}/ads/${adId}/report`,
	},
	pricing: {
		adsPricing: `${CLIENT}/ads/plan-tiers`,
	},
	agents: {
		base: `${CLIENT}/agents`,
		agentList: `${CLIENT}/agent-list`,
		updateAgent: (agentId: string) => `${CLIENT}/agents/${agentId}`,
		agentHasAd: (agentId: string) => `${CLIENT}/agents/${agentId}/assigned-ad`,
		business: `${PUBLIC}/business`,
		signUp: `${PUBLIC}/business/request`,
		list: `${PUBLIC}/business/hub`,
	},
	agentHub: {
		ads: `${CLIENT}/agent-hub/ads`,
		enquiries: `${CLIENT}/agent-hub/enquiries`,
	},
	matchingTenant: {
		getMatchingTenantList: (adId: string) => `${CLIENT}/ads/${adId}/tenant-match`,
		getMatchingTenantDetail: (adId: string, tenantId: string) => `${CLIENT}/ads/${adId}/tenant-match/${tenantId}`,
		submitTenantViewDate: (adId: string, tenantId: string) => `${CLIENT}/ads/${adId}/tenant-match/${tenantId}`,
	},
	meetingSchedule: {
		base: `${CLIENT}/meetings`,
		getMeetingTypes: `${PUBLIC}/filters/meeting-types`,
		getMeetingDays: `${PUBLIC}/filters/meeting-days`,
		getTimeSlots: `${PUBLIC}/filters/meeting-times`,
		getTimeZone: `${PUBLIC}/filters/timezones`,
		meetingEvent: `${PUBLIC}/meetings`,
		acceptMeeting: `${PUBLIC}/meetings/accept`,
		getAdsList: `${CLIENT}/my/ads/aisles`,
		meeting: (id) => `${CLIENT}/meetings/${id}`,
		updateStatus: (id, status) => `${CLIENT}/meetings/${id}/status/${status}`,
		deleteMeeting: (id) => `${CLIENT}/meetings/${id}`,
	},
	trackPhoneCall: (id) => `${CLIENT}/ads/${id}/call`,
};
