export enum SortType {
	DATE_LATEST = 'date-latest',
	DATE_OLDEST = 'date-oldest',
	PRICE_HIGHEST = 'price-highest',
	PRICE_LOWEST = 'price-lowest',
	KILOMETER_HIGHEST = 'kilometer-highest',
	KILOMETER_LOWEST = 'kilometer-lowest',
	YEAR_HIGHEST = 'year-highest',
	YEAR_LOWEST = 'year-lowest',
}
