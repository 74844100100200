// TODO: Use proper words of naming Enum
export const MY_ADS_HEADER_STATUS_MAPPING = {
	1: 'live',
	2: 'under-review',
	3: 'edit-under-review',
	4: 'draft',
	5: 'sold',
	6: 'rejected',
	7: 'payment-failed',
	8: 'payment-pending',
	9: 'expired',
};
