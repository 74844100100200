import { Injectable } from '@angular/core';
import { BusinessProfile, ClientUser, SubscriptionInfo } from '@core/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ProfileState {
	// TODO: Create a model in type 'any'

	private readonly _user$ = new BehaviorSubject<ClientUser>(undefined);
	private readonly _businessProfile$ = new BehaviorSubject<BusinessProfile>(undefined);
	private readonly _isLoading$ = new BehaviorSubject<boolean>(false);
	private readonly _profileTabIndex$ = new BehaviorSubject<number>(0);
	private readonly _subscriptionInfo$ = new BehaviorSubject<SubscriptionInfo>(undefined);

	get profileTabIndex$(): Observable<number> {
		return this._profileTabIndex$;
	}

	setProfileTabIndex(index: number): void {
		return this._profileTabIndex$.next(index);
	}

	get user$(): Observable<ClientUser> {
		return this._user$.asObservable();
	}

	get isLoading$(): Observable<boolean> {
		return this._isLoading$.asObservable();
	}

	get businessProfile$(): Observable<BusinessProfile> {
		return this._businessProfile$.asObservable();
	}

	get subscriptionInfo$(): Observable<any> {
		return this._subscriptionInfo$.asObservable();
	}

	setIsLoading(isLoading: boolean): void {
		this._isLoading$.next(isLoading);
	}

	setUser(user: ClientUser): void {
		this._user$.next(user);
	}

	updateUser(user: ClientUser): void {
		this._user$.next({ ...this.getUser(), ...user });
	}

	getUser() {
		return this._user$.getValue();
	}

	getBusinessProfile(): BusinessProfile {
		return this._businessProfile$.getValue();
	}

	setBusinessProfile(profile: BusinessProfile) {
		this._businessProfile$.next(profile);
	}

	getSubscriptionData(): SubscriptionInfo {
		return this._subscriptionInfo$.getValue();
	}

	setSubscriptionInfo(subscription: SubscriptionInfo) {
		this._subscriptionInfo$.next(subscription);
	}
}
