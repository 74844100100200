export enum FilterType {
	SECTION = 'section',
	LOCATION_REFERENCE = 'location',
	AISLE = 'aisle',
	STAND = 'stand',
	SHELF = 'shelf',
	MANUFACTURER = 'manufacturerId', // make
	PRODUCT = 'productId', // model
	ENGINE_SIZE_CC = 'engineSizeCcId',
	MILEAGE = 'mileageKm',
	HORSE_POWER = 'horsePowerId',
	STEERING_SIDE = 'isSteeringSideLeft',
	SELLER_TYPE = 'sellerTypeId',
	COUNTY = 'countyId',
	COUNTY_AREA = 'countyAreaId',
	PRICE = 'price',
	YEAR = 'year',
	KILOMETER = 'kilometer',
	TRIM = 'trimId',
	BODY_TYPE = 'bodyTypeId',
	REGIONAL_SPEC = 'regionalSpecId',
	DOOR_COUNT = 'doorCount',
	FUEL_TYPE = 'fuelTypeId',
	COLOR = 'colorId',
	BODY_CONDITION = 'bodyConditionId',
	MECHANICAL_CONDITION = 'mechanicalConditionId',
	IS_WARRANTY = 'isWarranty',
	CYLINDER_COUNT = 'cylinderCount',
	TRANSMMISION = 'transmissionId',
	VEHICLE_EXTRAS = 'vehicleExtras',
	DATE = 'date',
	FINAL_DRIVE_SYSTEM = 'finalDriveSystemId',
	USAGE = 'usageId',
	WHEEL_COUNT = 'wheelCount',
	CONDITION = 'conditionId',
	PLATE_DIGIT_COUNT = 'plateDigitCount',
	PROPERTY_TYPE = 'stand',
	BEDROOM_COUNT = 'bedroomCount',
	BATHROOM_COUNT = 'bathroomCount',
	FURNISHING = 'furnishingId',
	SIZE_SQUARE_METERS = 'sizeSquareMetres',
	PROPERTY_EXTRAS = 'propertyExtras',
	PROPERTY_SIZES = 'propertySize',
	IS_UNDER_CONSTRUCTION = 'isUnderConstruction',
	RENTAL_PERIOD = 'rentalPeriodId',
	ROOM_TYPE = 'roomTypeId',
	PARKING_AVAILABLE_SPACE_COUNT = 'parkingAvailableSpaceCount',
	PARKING_ACCESS = 'parkingAccessId',
	IS_OWNER_OCCUPIED = 'isOwnerOccupied',
	OTHER_TENANT_SHARE_COUNT = 'otherTenantShareCount',
	IS_ONE_PLUS_PERSON_ALLOWED = 'isOnePlusPersonAllowed',
	TENANT_PREFERENCE = 'tenantPreferenceId',
	SORT = 'sort',
	LONGTITUDE = 'longtitude',
	LATITUDE = 'latitude',
	RANGE = 'distanceRange',
	IS_SEARCH_NEAR_ME = 'isSearchNearMe',
}

export enum FilterTemplateType {
	MULTI_SELECTION,
	SINGLE_SELECTION,
	MIN_MAX_DROPDOWN,
	MIN_MAX_INPUT,
	BOOLEAN_SELECTION,
	BUTTON_SELECTION,
	SINGLE_DROPDOWN_SELECTION,
}

export enum FilterValueListEndpoint {
	PRICE = 'prices',
	COUNTY = 'county',
	COUNTY_AREA = 'county-area',
	MANUFACTURER = 'manufacturers',
	PRODUCT = 'products',
	TRIM = 'trims',
	BODY_TYPE = 'body-types',
	KILOMETER = 'kilometers',
	REGIONAL_SPEC = 'regional-specs',
	DOOR_COUNT = 'doors',
	FUEL_TYPE = 'fuel-types',
	COLOR = 'colors',
	BODY_CONDITION = 'body-conditions',
	MECHANICAL_CONDITION = 'mechanical-conditions',
	CYLINDER_COUNT = 'cylinders',
	TRANSMMISION = 'transmissions',
	HORSE_POWER = 'horse-powers',
	VEHICLE_EXTRAS = 'vehicle-extras',
	DATE = 'dates',
	FINAL_DRIVE_SYSTEM = 'final-drive-systems',
	USAGE = 'usages',
	STAND = 'stands',
	SHELF = 'shelves',
	ENGINE_SIZE_CC = 'engine-size-ccs',
	WHEEL_COUNT = 'wheels',
	CONDITION = 'conditions',
	PLATE_DIGIT_COUNT = 'plate-digits',
	BEDROOM_COUNT = 'bedrooms',
	BATHROOM_COUNT = 'bathrooms',
	FURNISHING = 'furnishings',
	PROPERTY_EXTRAS = 'property-extras',
	PORPERTY_SIZES = 'property-sizes',
	RENTAL_PERIOD = 'rental-periods',
	YEAR = 'years',
	SELLER_TYPE = 'seller-types',
	ROOM_TYPES = 'room-types',
	PARKING_ACCESS = 'parking-access',
	TENANT_PREFERENCE = 'tenant-preferences',
	OTHER_TENANT_SHARE_COUNT = 'tenants',
}

export enum FiltersPanelClass {
	FILTERS_MENU = 'filters filters--filters-menu',
	BASE_SELECTION_FILTER = 'filters filters--base-selection-filters',
	BASE_MIN_MAX_FILTER = 'filters filters--base-min-max-filters',
}

export enum SectionType {
	MOTORS = 'motors',
	PROPERTIES_FOR_RENT = 'properties-for-rent',
	PROPERTIES_FOR_SALE = 'properties-for-sale',
}

export enum AisleType {
	CARS = 'cars',
	TAXI = 'taxi',
	NUMBER_PLATES = 'number-plates',
	BUY_RESIDENTIAL = 'buy-residential',
	RENT_RESIDENTIAL = 'rent-residential',
	SHARE_RESIDENTIAL = 'share-residential',
	RENT_COMMERCIAL = 'rent-commercial',
	BUY_COMMERICAL = 'buy-commercial',
	NEW_PROJECTS = 'new-projects',
	SALE_RESIDENTIAL = 'sell-residential',
	SALE_COMMERCIAL = 'sell-commercial',
}

export enum CountyType {
	DEFAULT = 'ireland',
}

export enum FilterAction {
	// Update Filter values
	UPDATE,
	// Restore Filter values
	RESTORE,
}
