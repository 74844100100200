export enum TenantViewDateStatus {
	EDIT = 'EDIT',
	CONFIRM = 'CONFIRM',
}

export enum TenantDay {
	SUNDAY = 0,
	MONDAY,
	TUESDAY,
	WEDNESDAY,
	THURSDAY,
	FRIDAY,
	SATURDAY,
}

export enum TenantTimeSlotRefercence {
	MORNING = 'morning-9am-12pm',
	MID_DAY = 'midday-12pm-3pm',
	AFTER_NOON = 'Afternoon-3pm-6pm',
	EVENING = 'evening-6pm-9pm',
}
