export enum AdAisle {
	CAR_DETAILS = 'cars',
	MOTOR_CYCLES = 'motorcycles',
	HEAVY_VEHICLES = 'heavy vehicles',
	NUMBER_PLATES = 'numberplates',
	TAXI = 'taxi',
	ACCESSORIES = 'accessories & parts',
	BUY_RESIDENTIAL = 'buy residential',
	RENT_RESIDENTIAL = 'rent residential',
	SHARE_RESIDENTIAL = 'share residential',
	BUY_COMMERCIAL = 'buy commercial',
	RENT_COMMERCIAL = 'rent commercial',
	NEW_PROJECTS = 'new projects',
}

export enum SellerType {
	INDIVIDUAL = '1',
	BUSINESS = '2',
}

export enum AdListKind {
	SIMILAR = 'ads#similar',
	LIST = 'ads#list',
}
