import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
	selector: 'findqo-snack',
	templateUrl: './snack.component.html',
	styleUrls: ['./snack.component.scss'],
})
export class SnackComponent {
	constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, private snackBarRef: MatSnackBarRef<SnackComponent>) {}

	onClose(): void {
		this.snackBarRef.dismiss();
	}
}
