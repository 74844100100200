import { HttpParams } from '@angular/common/http';
import { SimpleChange } from '@angular/core';
import { throwError } from 'rxjs';

export class CommonUtil {
	static removeEmptyQueryParams(params: any): any {
		return Object.keys(params).reduce((accumulator: any, key: string) => {
			if (params[key] && !(Array.isArray(params[key]) && !params[key].length)) {
				accumulator[key] = params[key];
			}

			return accumulator;
		}, {});
	}

	static handleNgOnChanges(prop: SimpleChange, callback: (value: any) => void): void {
		if (prop !== undefined && prop.currentValue !== undefined) {
			callback(prop.currentValue);
		}
	}

	static createHttpParams(json: object): HttpParams {
		let params = new HttpParams();

		if (!this.isEmptyObject(json)) {
			Object.entries(json).forEach(([key, value]) => {
				if (key && value) {
					params = params.set(key, value);
				}
			});
		}

		return params;
	}

	/**
	 * Check if object is empty or not
	 *
	 * @param object
	 * @returns { boolean } Is object empty
	 */
	static isEmptyObject = <T>(object: T): boolean => {
		return !object || (object && !Object.keys(object).length);
	};

	static handleError = (error: any): any => {
		let errorMessage = '';

		if (error.error instanceof ErrorEvent) {
			// client-side error
			errorMessage = `Error: ${error.error.message}`;
		} else {
			// server-side error
			errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
		}

		return throwError(() => {
			return errorMessage;
		});
	};

	/**
	 * Check if the a value is a type of object
	 */
	static isTypeOfObject = <T>(object: T): boolean => {
		return !!object && typeof object === 'object';
	};

	/**
	 * Convert digit to commas
	 */
	static numberWithCommas = (digit: number): string => {
		return digit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	};

	/**
	 * HACK: Fix expression has changed after it was checked
	 * We are using setTimeout() to defer this code to another Javascript Virtual Machine turn, without specifying the value for timeout.
	 */
	static handleExpressionChangedError = (callback: (value?: unknown) => void, args?: unknown): void => {
		setTimeout(() => {
			callback(args);
		});
	};

	/**
	 * Check if object has existing property
	 */
	static hasOwnProperty = <T>(object: T, key: string): boolean => {
		return !!object && object.hasOwnProperty(key);
	};
}
