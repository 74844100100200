import { Injectable } from '@angular/core';
import { AisleType, SectionType } from '@core/enums';
import { Aisle, Section, Stand } from '@core/models';
import { BehaviorSubject, Observable, filter, map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SectionsState {
	private readonly _sectionList$ = new BehaviorSubject<Section[]>([]);

	get sectionList$(): Observable<Section[]> {
		return this._sectionList$.asObservable();
	}

	setSectionList(sectionList: Section[]): void {
		this._sectionList$.next(sectionList);
	}

	getStands(currentSection: string, currentAisle: string): Stand[] {
		return this.getAisle(currentSection, currentAisle)?.stands;
	}

	getAisle(currentSection: string, currentAisle: string): Aisle {
		const SECTION = this.getSection(currentSection);

		return SECTION?.aisles.find((aisle: Aisle) => currentAisle === aisle.reference);
	}

	getSection(currentSection: string): Section {
		const SECTION_LIST = this._sectionList$.getValue();

		return SECTION_LIST?.find((section: Section) => currentSection === section.reference);
	}

	getSortedAisleList$(section: string): Observable<Aisle[]> {
		const RENT_ORDER = [AisleType.SHARE_RESIDENTIAL, AisleType.RENT_RESIDENTIAL, AisleType.RENT_COMMERCIAL];
		const SALE_ORDER = [AisleType.SALE_RESIDENTIAL, AisleType.SALE_COMMERCIAL];
		const ICON_MAPPER = {
			[AisleType.SHARE_RESIDENTIAL]: 'meeting_room',
			[AisleType.RENT_RESIDENTIAL]: 'home_work',
			[AisleType.RENT_COMMERCIAL]: 'domain',
			[AisleType.SALE_COMMERCIAL]: 'domain',
			[AisleType.SALE_RESIDENTIAL]: 'home',
		};

		return this.sectionList$.pipe(
			filter((sectionList: Section[]) => !!sectionList?.length),
			map(() => {
				const AISLE_ORDER = section === SectionType.PROPERTIES_FOR_RENT ? RENT_ORDER : SALE_ORDER;

				let aisleList = this.getSection(section).aisles;

				// Sort Aisle
				aisleList = aisleList.sort(
					(aisleA: Aisle, aisleB: Aisle) => AISLE_ORDER.indexOf(aisleA.reference as AisleType) - AISLE_ORDER.indexOf(aisleB.reference as AisleType)
				);

				return aisleList.map((aisle: Aisle) => {
					return {
						...aisle,
						icon: ICON_MAPPER[aisle.reference],
					};
				});
			})
		);
	}
}
