// to check the boolean API response
export enum BooleanStatus {
	FALSE = '0',
	TRUE = '1',
}

export enum DeviceType {
	IPHONE = 'iPhone',
	ANDROID = 'Android',
}
