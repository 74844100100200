import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { API } from '@core/constants';
import { FilterType, FilterValueListEndpoint } from '@core/enums';
import {
	CommonItem,
	CommonResponse,
	Filter,
	FilterDropdownValue,
	FilterValue,
	GetFilterListResponse,
	GetSectionListResponse,
	RecentSearch,
	Section,
	SectionAisle,
} from '@core/models';
import { CACHE_REQUEST } from '@core/services/http-cache.service';
import { CommonUtil } from '@core/utils/common.util';
import { environment } from '@environments/environment';
import { Observable, map, of } from 'rxjs';
import * as sections from '../../../shared/json/sections/sections.json';

const DEFAULT_TTL = 3000000;

@Injectable({
	providedIn: 'root',
})
export class FiltersApi {
	constructor(private http: HttpClient) {}

	readonly featureVersion = environment.featureVersion;
	readonly filterType = FilterType;

	getFilters(body: any): Observable<Filter[]> {
		return this.http
			.get<GetFilterListResponse>(API.filters.filterList, {
				params: CommonUtil.createHttpParams(body),
				context: new HttpContext().set(CACHE_REQUEST, {
					cached: true,
					ttl: DEFAULT_TTL,
				}),
			})
			.pipe(map((response: GetFilterListResponse) => response.data || []));
	}

	getSectionList(): Observable<Section[]> {
		return of(sections.data);
	}

	getShelves(body: { stand: string }) {
		return this.http.get(API.filters.shelveList, {
			params: CommonUtil.createHttpParams(body),
			context: new HttpContext().set(CACHE_REQUEST, {
				cached: true,
				ttl: DEFAULT_TTL,
			}),
		});
	}

	getFilterValue(filterValueEndpoint: string, params?: Params): Observable<FilterValue> {
		return this.http
			.get<CommonResponse<FilterValue>>(API.filters.filterValues(filterValueEndpoint), {
				params: CommonUtil.createHttpParams({
					...params,
					...(filterValueEndpoint === FilterValueListEndpoint.STAND && { withShelves: 1 }),
					...(filterValueEndpoint === FilterValueListEndpoint.COUNTY && { withCountyArea: 1 }),
				}),
				context: new HttpContext().set(CACHE_REQUEST, {
					cached: true,
					ttl: DEFAULT_TTL,
				}),
			})
			.pipe(map((response: CommonResponse<FilterValue>) => response.data));
	}

	getSectionAisle(section: string): Observable<SectionAisle> {
		return this.http.get<SectionAisle>(API.filters.aisleList, {
			params: {
				section: section,
				withAdCount: 1,
			},
			context: new HttpContext().set(CACHE_REQUEST, {
				cached: true,
				ttl: DEFAULT_TTL,
			}),
		});
	}

	getStandsList(aisle: string): Observable<GetSectionListResponse> {
		return this.http.get<GetSectionListResponse>(API.filters.standlist, {
			params: { aisle },
			context: new HttpContext().set(CACHE_REQUEST, {
				cached: true,
				ttl: DEFAULT_TTL,
			}),
		});
	}

	getCountyList(params): Observable<FilterDropdownValue[]> {
		return this.http.get(API.filters.getCountyList, { params }).pipe(map((response: CommonResponse<FilterDropdownValue[]>) => response.data));
	}

	getCountyAreaList(countyId: string): Observable<FilterDropdownValue[]> {
		return this.http
			.get<CommonResponse<FilterDropdownValue[]>>(`${API.filters.filterList}/${FilterValueListEndpoint.COUNTY_AREA}/${countyId}`, {
				context: new HttpContext().set(CACHE_REQUEST, {
					cached: true,
					ttl: DEFAULT_TTL,
				}),
			})
			.pipe(map((response: CommonResponse<FilterDropdownValue[]>) => response.data));
	}

	getAvailibilitySlots(): Observable<CommonItem[]> {
		return this.http
			.get<CommonResponse<CommonItem[]>>(`${API.filters.availibilitySlots}`, {
				context: new HttpContext().set(CACHE_REQUEST, {
					cached: true,
					ttl: DEFAULT_TTL,
				}),
			})
			.pipe(map((response: CommonResponse<CommonItem[]>) => response?.data));
	}

	getRecentSearch(): Observable<CommonResponse<RecentSearch[]>> {
		return this.http.get<CommonResponse<RecentSearch[]>>(API.filters.getRecentSearch);
	}
}
