export enum AuthType {
	LOGIN,
	OTP,
	WELCOME,
	CHANGE_EMAIL,
	NEW_EMAIL,
	UPDATE_EMAIL_OTP,
	NEW_EMAIL_OTP,
	DELETE_OTP,
	INTEREST_SURVEY,
}

export enum UserRoles {
	IS_AGENT_VERIFIED = 'isAgentUser',
	IS_EMAIL_VERIFIED = 'isEmailVerified',
}

export enum ErrorId {
	PERMISSION_DENIED = 'permissionDenied',
	ACCOUNT_DISABLED = 'accountDisabled',
}

export enum UpdateAction {
	ADD = 'add',
	REMOVE = 'remove',
	UPDATE = 'update',
}

export enum MenuKeys {
	ADDS_COUNT = 'adsCount',
	ENQUIRY_COUNT = 'enquiryCount',
	HAS_BUSINESS_PROFILE = 'hasBusinessProfile',
	LIVE_ADS = 'liveAds',
}
