import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackComponent } from '../../core/components/snack/snack.component';

@Injectable({
	providedIn: 'root',
})
export class SnackService {
	constructor(private snackbar: MatSnackBar) {}

	open(config: MatSnackBarConfig) {
		return this.snackbar.openFromComponent(SnackComponent, {
			data: config.data,
			panelClass: config.panelClass,
			duration: config.duration ?? 5000,
			horizontalPosition: !!config?.horizontalPosition ? config?.horizontalPosition : 'center',
		});
	}
}
