export enum LocaleType {
	ENGLISH = 'en',
	KURDISTAN = 'ku',
	ARABIC = 'ar',
}

export enum LocaleDirection {
	RTL = 'rtl',
	LTR = 'ltr',
}
