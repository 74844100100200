import { SortType } from '@core/enums/sort.enum';

export const DROPDOWN_SORT = [
	{
		value: 'Sort by newest',
		key: SortType.DATE_LATEST,
	},
	{
		value: 'Sort by oldest',
		key: SortType.DATE_OLDEST,
	},
	{
		value: 'Price: Low to High',
		key: SortType.PRICE_LOWEST,
	},
	{
		value: 'Price: High to Low',
		key: SortType.PRICE_HIGHEST,
	},
	{
		value: 'Mileage: Low to High',
		key: SortType.KILOMETER_LOWEST,
	},
	{
		value: 'Mileage: High to Low',
		key: SortType.KILOMETER_HIGHEST,
	},
];
