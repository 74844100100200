import { FeatureVersion } from '@core/enums';

export const environment = {
	production: false,
	featureVersion: FeatureVersion.V2,
	apiUrl: 'https://stag-api.findqo.com/ie/api/v1',
	apiImageUrl: 'https://stag-images.findqo.com',
	firebase: {
		apiKey: 'AIzaSyBFN_Cq4F3nabFSHDOd5yaZouvItU5oON4',
		authDomain: 'findqo.firebaseapp.com',
		projectId: 'findqo',
		storageBucket: 'findqo.appspot.com',
		messagingSenderId: '1029429918390',
		appId: '1:1029429918390:web:5981858e2043f41ed18417',
	},
	cryptoJSKey: 'cpX&hWxZ!r%x,|89.S]UOVD<]_<Sc.G3',
	defaultAvatarImg: 'https://stag-images.findqo.com/profile-avatar/Default.png',
	WHATSAPP: {
		sendMessage: 'https://api.whatsapp.com/send?phone=',
	},
	googleMapUrl: 'https://www.google.com/maps/search/?api=1&query=',
	tenantInSignUpUrl: 'https://tenantin.ie/auth/register',
	// TODO: Remove all condition related on this variable if Seller module is ready to be merged to Buyer module
	isSellerModuleEnabled: true,
	endPointUrl: 'http://localhost:4000/',
	intercomId: 'yfchiyw0',
	browserOutputPath: 'dist/findqo-website-ie/browser',
	googleAnalyticsId: undefined,
	appUrl: 'https://stag.findqo.ie',
	tenantInCreateProfileUrl: 'https://dev.tenantin.ie/auth/login',
	tenantInWhyUrl: 'https://tenantin.ie/why',
	agentYoutubeUrl: 'https://www.youtube.com/watch?v=cCngMZ7VTSg',
	agentPdf: 'https://images.findqo.com/assets/What.is.FindQo.ie.Agents.pdf',
	tenantIn: 'https://dev.tenantin.ie/',
	feedbackUrl: 'https://findqo.ie/feedback',
	defaultLocation: 'ireland',
	googleAdSense: {
		display: 'block',
		adClient: 'ca-pub-9744286879890717',
		banner: {
			adSlot: '1310272079',
			adFormat: 'auto',
			fullWidthResponsive: true,
		},
	},
	mobileAppRedirectAPI: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBFN_Cq4F3nabFSHDOd5yaZouvItU5oON4',
	appUrlForDeeplink: 'https://www.staging.findqo.com',
	tenantinApiBaseUrl: 'https://dev-api.tenantin.ie/api/v1',
	metaPixelId: '',
	googleTagManagerId: '',
};
