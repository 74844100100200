/**
 * Add here all error related enums
 */

export enum ErrorType {
	EMAIL = 'email',
	REQUIRED = 'required',
	NUMBER = 'number',
}

// TODO: This is not used anywhere, this is for an example how we should use this enum. Check if this is still needed then ask for translations, if not then just remove it.
export enum ErrorMessage {
	DEFAULT = 'n3239',
	EMAIL = 'n3087',
	REQUIRED = 'n3113',
}
