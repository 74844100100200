import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { StorageType } from '@core/enums/storage.enum';

@Injectable({
	providedIn: 'root',
})
export class StorageService {
	constructor(@Inject(PLATFORM_ID) private platformId: any) {}

	setItem(key: string, value: string): void {
		if (isPlatformBrowser(this.platformId)) {
			localStorage.setItem(key, value);
		}
		// if (isPlatformBrowser(this.platformId)) {
		// 	let LS_KEY = makeStateKey<string>(key);
		// 	if (this.transferState.hasKey(LS_KEY)) {
		// 		this.transferState.remove(LS_KEY);
		// 	}
		// 	localStorage.setItem(key, this.encrypt(value));
		// } else {
		// 	let LS_KEY = makeStateKey<string>(key);
		// 	this.transferState.set(LS_KEY, value);
		// }
	}

	getItem(key: string): any {
		if (isPlatformBrowser(this.platformId)) {
			let data = localStorage.getItem(key) || '';
			return data;
		}
		return null;
		// var value;
		// if (isPlatformBrowser(this.platformId)) {
		// 	let LS_KEY = makeStateKey<string>(key);
		// 	if (this.transferState.hasKey(LS_KEY)) {
		// 		value = this.transferState.get<string>(LS_KEY, null);
		// 		localStorage.setItem(key, this.encrypt(value));
		// 		this.transferState.remove(LS_KEY);
		// 		return value;
		// 	} else {
		// 		value = localStorage.getItem(key) || '';
		// 		return this.decrypt(value);
		// 	}
		// } else {
		// 	let LS_KEY = makeStateKey<string>(key);
		// 	value = this.transferState.get<string>(LS_KEY, null);
		// 	return value;
		// }
	}

	removeItem(key: string): void {
		if (isPlatformBrowser(this.platformId)) {
			localStorage.removeItem(key);
		}
		// var value;
		// if (isPlatformBrowser(this.platformId)) {
		// 	let LS_KEY = makeStateKey<string>(key);
		// 	if (this.transferState.hasKey(LS_KEY)) {
		// 		this.transferState.remove(LS_KEY);
		// 	}
		// 	localStorage.removeItem(key);
		// } else {
		// 	let LS_KEY = makeStateKey<string>(key);
		// 	this.transferState.remove(LS_KEY);
		// }
	}

	clear(): void {
		const LOCALE = this.getItem(StorageType.LOCALE);
		const TRANSLATIONS = this.getItem(StorageType.TRANSLATIONS);

		if (isPlatformBrowser(this.platformId)) {
			localStorage.clear();
		}
		// Locale related data should be preserved when clearing local storage
		this.setItem(StorageType.LOCALE, LOCALE);
		this.setItem(StorageType.TRANSLATIONS, TRANSLATIONS);
	}

	clearStorage() {
		localStorage.clear();
	}

	// private encrypt(value: string): string {
	// 	return CryptoJS.AES.encrypt(value, env.cryptoJSKey).toString();
	// }

	// private decrypt(value: string) {
	// 	return CryptoJS.AES.decrypt(value, env.cryptoJSKey).toString(CryptoJS.enc.Utf8);
	// }
}
