export const DEFAULT_META_CONTENTS = {
	title: 'n3233',
	type: 'website',
	image: '/assets/images/website-og-cover-photo.webp',
	siteName: 'FindQo.ie',
};

export const HOME_META_CONTENTS = {
	title: 'Properties to buy, sell, share and rent in Ireland | FindQo',
	description:
		'FindQo.ie: Buy, sell, or rent properties in Ireland. Find houses, apartments, land, offices and more from trusted agents and landlords. Free for all users.',
};

export const ABOUT_META_CONTENTS = {
	description: `FindQo.ie engineering team, a team of 31 highly skilled Software Engineers, designers, and Solution Architects with the mission to address Ireland's unique property rental challenges mixed with the latest tech.`,
};

export const AGENT_SIGNUP_META_CONTENTS = {
	title: 'Register Free to Advertise your property - FindQo Sign up',
	description: `Sign up to advertise your Real Estate in Ireland for Free. Post houses, apartments, shops, offices and more absolutely free.`,
};

export const AGENT_LANDING_META_CONTENTS = {
	title: 'Advertise Properties in Ireland - Agents Start Free | FindQo',
	description: `Post your real estate ads effortlessly in Ireland! Reach more buyers and sellers with targeted property listings. Start now and boost your listings.`,
};

export const SELLER_META_CONTENTS = {
	description: (seller: string, address: string) => `${seller} properties in ${address}`,
};

export const CONTACT_US_SEO = {
	title: 'Contact Real Estate Agent in Ireland - FindQo',
	description: `Contact us for top real estate services in Ireland! Connect with agents, explore listings, and get expert advice. Your dream home awaits!`,
};

export const PLACE_AD_GUIDE_META_CONTENTS = {
	title: 'Advertise your property - List Irish Real Estate Ads Free',
	description: 'Advertise your real estate in Ireland for Free. Post houses, apartments, shops, offices, bungalows and more absolutely free.',
};
