import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { API } from '@core/constants';
import {
	BusinessProfile,
	ClientUser,
	CommonResponse,
	PaymentLink,
	SubscriptionInfo,
	SurveyBody,
	UpdateBusinessProfileRequest,
	UpdateProfilePhoneRequest,
	UploadPhotoResponse,
} from '@core/models';
import { CommonUtil } from '@core/utils/common.util';
import { Observable, map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ProfileApi {
	protected constructor(private readonly http: HttpClient) {}

	getProfile(withBusinessProfile = '1'): Observable<{ data: ClientUser }> {
		return this.http.get<{ data: ClientUser }>(API.profile.base, {
			params: CommonUtil.createHttpParams({ withBusinessProfile }),
		});
	}

	updateProfile(request: any): Observable<CommonResponse<Record<string, never>>> {
		return this.http.patch(API.profile.base, request);
	}

	deleteProfile(request?: Params): Observable<CommonResponse<Record<string, never>>> {
		return this.http.delete(API.profile.base, { params: CommonUtil.createHttpParams(request) });
	}

	checkMobileNoExist(request: any): Observable<CommonResponse<Record<string, never>>> {
		return this.http.post(API.profile.checkMobileNoExist, request);
	}

	clientEmailPreference(request: any): Observable<CommonResponse<Record<string, never>>> {
		return this.http.patch(API.profile.preference, request);
	}

	updateProfilePhone(request: UpdateProfilePhoneRequest): Observable<CommonResponse<Record<string, never>>> {
		return this.http.patch(API.profile.updateProfilePhone, request);
	}

	sentEmailOtp(request: any): Observable<CommonResponse<Record<string, never>>> {
		return this.http.patch(API.profile.sendOtp, request);
	}

	confirmEmailOtp(request: any): Observable<CommonResponse<Record<string, never>>> {
		return this.http.patch(API.profile.verfiyOtp, request);
	}

	getAwsProfilePhotoUrl(): Observable<{ data: UploadPhotoResponse }> {
		return this.http.post<{ data: UploadPhotoResponse }>(API.profile.getProfilePicture, {});
	}

	postUploadedPhoto(url: any, file: Blob) {
		return this.http.put(url, file, { headers: { 'Content-Type': file.type } });
	}

	deleteUserProfilePhoto(request: any) {
		return this.http.delete(API.profile.deleteProfilePicture, request);
	}

	getUserAvatar(): Observable<CommonResponse<Record<string, never>>> {
		return this.http.get(API.profile.getAvatar);
	}

	// TODO: Add type here
	updateUserAvatar(url: any): Observable<CommonResponse<Record<string, never>>> {
		return this.http.patch(API.profile.updateAvatar, url);
	}

	getRequestOtp(): Observable<CommonResponse<Record<string, never>>> {
		return this.http.post<CommonResponse<{}>>(API.profile.getRequestedOtp, {});
	}

	getBusinessProfile(): Observable<CommonResponse<BusinessProfile>> {
		return this.http.get(API.profile.businessProfile);
	}

	// TODO: Add type here
	updateBusinessProfile(request: UpdateBusinessProfileRequest) {
		return this.http.patch(API.profile.businessProfile, request);
	}

	// TODO: Add type here
	getAwsBusinsessProfilePhotoUrl() {
		return this.http.post(API.profile.businessAwsUrl, {}).pipe(map((response) => response?.['data']));
	}

	changeEmailRequestOtp(): Observable<CommonResponse<Record<string, never>>> {
		return this.http.post<CommonResponse<Record<string, never>>>(API.profile.changeEmailRequestOtp, {});
	}

	verfiyCurrentEmailOtp(pin: string): Observable<CommonResponse<Record<string, never>>> {
		return this.http.post<CommonResponse<Record<string, never>>>(API.profile.changeEmailVerifyOtp, pin);
	}

	updateEmailOtp(email: string): Observable<CommonResponse<Record<string, never>>> {
		return this.http.post<CommonResponse<Record<string, never>>>(API.profile.updateEmailOtp, { email: email });
	}

	verfiyNewEmailOtp(pin: string): Observable<CommonResponse<Record<string, never>>> {
		return this.http.patch<CommonResponse<Record<string, never>>>(API.profile.sendOtp, pin);
	}

	unSubscribeEmail(body: { token: string }): Observable<CommonResponse<object>> {
		return this.http.post<CommonResponse<object>>(API.profile.unSubscribeEmail, body);
	}

	submitSurvey(body: Partial<SurveyBody>): Observable<CommonResponse<object>> {
		return this.http.post<CommonResponse<object>>(API.profile.submitSurvey, body);
	}

	generatePaymentLink(body): Observable<CommonResponse<PaymentLink>> {
		return this.http.post<CommonResponse<PaymentLink>>(API.profile.generatePaymentLink, body);
	}

	verifyPayment(body): Observable<CommonResponse<object>> {
		return this.http.post<CommonResponse<object>>(API.profile.verifyPayment, body);
	}

	patchAgentPhone(agentId, body): Observable<CommonResponse<object>> {
		return this.http.patch<CommonResponse<object>>(API.agents.updateAgent(agentId), body);
	}

	getSubscriptionInfo(): Observable<CommonResponse<SubscriptionInfo>> {
		return this.http.get(API.profile.subscription);
	}

	cancelSubscription(body): Observable<CommonResponse<object>> {
		return this.http.post(API.profile.cancelSubscription, body);
	}
}
