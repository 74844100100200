<div class="snackbar-container">
	<div class="snackbar-message fs-14px fw-400 d-flex align-items-center">
		<div class="d-inline-block align-middle">
			@if (data.type === 'success') {
				<mat-icon class="material-icons-round">check_circle</mat-icon>
			} @else {
				<mat-icon class="material-icons-round">{{ data?.icon ? data.icon : 'warning' }}</mat-icon>
			}
		</div>

		<span class="d-inline-block align-middle ms-2 text-body-regular">{{ data?.message ? data.message : (data?.translationKey | translations) }}</span>
	</div>
</div>
